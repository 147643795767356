var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"inconfont__container"},[_vm._m(0),_c('div',{staticClass:"margin"},[_vm._v(" 切换字体大小: "),_c('el-switch',{model:{value:(_vm.fontflag),callback:function ($$v) {_vm.fontflag=$$v},expression:"fontflag"}})],1),_c('div',{staticClass:"margin"},[_vm._v(" 切换字体颜色: "),_c('el-switch',{model:{value:(_vm.colorflag),callback:function ($$v) {_vm.colorflag=$$v},expression:"colorflag"}})],1),_c('div',{staticClass:"icon__container"},_vm._l((_vm.icons),function(icon){return _c('div',{key:icon.class,staticClass:"box"},[_c('i',{class:[
          'iconfont',
          icon.class,
          { font: _vm.fontflag },
          { color: _vm.colorflag },
        ]}),_c('p',[_vm._v(_vm._s(icon.name))]),_c('p',[_vm._v(_vm._s(icon.class))])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v(" 图标引入自"),_c('a',{attrs:{"href":"https://www.iconfont.cn/","target":"_blank"}},[_vm._v("iconfont库")]),_vm._v("，你也可以在iconfont中创建自己的图标项目然后引入使用 ")])
}]

export { render, staticRenderFns }